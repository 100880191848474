import React, { FunctionComponent } from 'react';
import { Box } from '@wix/design-system';
import { LoaderIcon } from './LoaderIcon';
import css from './index.module.scss';
import classNames from 'classnames';

type LoaderProps = {
  isLoading: boolean;
};

export const Loader: FunctionComponent<LoaderProps> = ({ isLoading }) => (
  <Box
    className={classNames(css.wrapper, { [css.active]: isLoading })}
    align="center"
    verticalAlign="middle"
  >
    <LoaderIcon />
  </Box>
);
